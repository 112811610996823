import React from "react"

import { withMaintenanceForm } from "./withMaintenanceForm"
import { Wrapper, Form, Error } from "./MaintenanceFormStyles"
import { StyledInput } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"

export const MaintenanceForm = withMaintenanceForm(
  ({ data, error, handleChange, handleSubmit }): JSX.Element => (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <StyledInput name="password" type="password" value={data?.password} onChange={handleChange} placeholder={"password"} required />
        <StyledButton size={"tertiary"} colour={"red-reverse"} type={`submit`}>
          Enter
        </StyledButton>
      </Form>
      {error && <Error>Invalid password</Error>}
    </Wrapper>
  )
)
