import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  mt-1 md:max-w-34
`

export const Form = styled.form`
  ${tw`flex flex-row pb-0-8`}
  button {
    ${tw`ml-1-2`}
  }
  input,
  textarea {
    border-radius: 0;
  }
  input,
  textarea,
  select {
    -webkit-appearance: none;
  }
`

export const Error = tw.p`
  text-red text-12 leading-none uppercase
`
