import React from "react"
import ReactPlayer from "react-player/lazy"
import Img from "gatsby-image"

import { withMaintenance } from "./withMaintenance"
import {
  Outer,
  Content,
  Section,
  Title,
  UsePassword,
  ImageWrapper,
  SubscribeWrapper,
  LandingWrapper,
  LandingPasswordWrapper,
  LandingPassword,
  LogoWrapper,
} from "./MaintenanceStyles"
import { MaintenanceForm } from "./Form/MaintenanceForm"
import { Main } from "../Main/Main"
import { Subscribe } from "../Subscribe/Subscribe"
import { StyledContainer } from "../Styled/Container"
import { Image } from "../Image/Image"
import { RichText } from "../RichText/RichText"

export const Maintenance = withMaintenance(
  ({ title, content, image, location, handleClick, active }): JSX.Element => (
    <Main>
      <Outer>
        <Content>
          <Section>
            <Title>{title}</Title>
            <RichText>{content}</RichText>
            <UsePassword colour={"yellow"} onClick={handleClick}>
              Password
            </UsePassword>
            {active ? <MaintenanceForm location={location} /> : null}
          </Section>
        </Content>
        <ImageWrapper>
          <Image image={image} />
        </ImageWrapper>
      </Outer>
    </Main>
  )
)

export const Landing = withMaintenance(
  ({ video, location, handleClick, active, logo }): JSX.Element => (
    <Main>
      <LandingWrapper>
        <StyledContainer width={"xl"} collapse={"default"}>
          <ReactPlayer playsinline muted url={video} playing loop width={`100%`} height={`100%`} />
          <SubscribeWrapper>
            <LogoWrapper>
              <Img fluid={logo} alt={"Driza bone brand logo"} />
            </LogoWrapper>
            <Subscribe layout={"password"} />
          </SubscribeWrapper>
        </StyledContainer>
      </LandingWrapper>
      <LandingPasswordWrapper>
        <LandingPassword onClick={handleClick}>Use Password</LandingPassword>
        {active ? <MaintenanceForm location={location} /> : null}
      </LandingPasswordWrapper>
    </Main>
  )
)
