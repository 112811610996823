import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

import { useImage } from "../../hooks/useImage"
import { useDevices } from "../../hooks/useDevices"

export const withMaintenance = Component => ({ name = "Maintenance", page, location }) => {
  const { getFluidImage } = useImage()
  const { isMobile } = useDevices()
  const [active, setActive] = useState(false)

  const { title, content, video, image: originalImage } = page
  const image = getFluidImage(originalImage)

  const handleClick = () => setActive(prev => !prev)

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  Component.displayName = name
  return (
    <Component
      title={title}
      content={content}
      video={video}
      image={image}
      location={location}
      active={active}
      handleClick={handleClick}
      logo={logo?.file?.childImageSharp?.fluid}
      isMobile={isMobile}
    />
  )
}
