import React from "react"
import { graphql } from "gatsby"

import { Maintenance, Landing } from "../components/Maintenance/Maintenance"

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      title
      password
      enabled
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      video
      image: _rawBackgroundImage(resolveReferences: { maxDepth: 2 })
      layout
    }
  }
`

const Component = ({ data, ...props }) => (data?.page?.layout === "landing" ? <Landing {...props} {...data} /> : <Maintenance {...props} {...data} />)
export default Component
