import tw, { styled } from "twin.macro"
import { StyledButton } from "../Styled/Button"
import { Heading12 } from "../Styled/Text"

export const Outer = tw.div`
  max-w-lg mx-auto md:px-4 flex md:flex-row flex-col-reverse
`

export const Content = tw.div`
  w-full md:w-1/2 px-2 py-2 md:pt-4 md:pb-7-2 flex items-center
`

export const Section = tw.div`
  w-full md:max-w-34 mx-auto flex flex-col 
`

export const Title = styled(Heading12)`
  ${tw`mb-2`}
`

export const UsePassword = styled(StyledButton)`
  ${tw`mt-2`}
`

export const ImageWrapper = tw.div`
  w-full md:w-1/2 px-2 py-2 md:pt-4 md:pb-7-2 
`

export const SubscribeWrapper = tw.div`
  bg-black text-white text-center p-2 md:p-4
`

export const LandingWrapper = tw.div`
  min-h-full-vh bg-black
`

export const LandingPasswordWrapper = tw.div`
  bg-black text-white px-2
`

export const LandingPassword = styled(StyledButton)`
  ${tw`text-black`}
`
export const LogoWrapper = tw.div`
  w-full max-w-28 md:max-w-34 mx-auto mb-2
`
